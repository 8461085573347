import React, { useEffect, useState } from 'react';
import axios from 'axios'
function PresentationResultsModal({ setIsOpen, downloadURL, setToastData }) {
  const [textPreview, setTextPreview] = useState('');

    const handleDownload = async () => {
        try {
            const response = await fetch(downloadURL);
            if (!response.ok) throw new Error('Network response was not ok');
            
            const data = await response.blob();
            const blobUrl = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', 'presentation.txt');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    useEffect(() => {
    const fetchTextFile = async () => {
    //   if (isOpen) {
        try {
          const response = await axios.get(downloadURL);
          setTextPreview(response.data);
        } catch (error) {
          console.error('Error fetching the text file:', error);
        }
      }
    // };

    fetchTextFile();
  }, [downloadURL]);

    const handleCopyToClipboard = async () => {
        try {
            const response = await fetch(downloadURL);
            if (!response.ok) throw new Error('Network response was not ok');
            
            const text = await response.text();
            await navigator.clipboard.writeText(text);
            setToastData({ text: "Contents copied to clipboard.", show: true });
        } catch (error) {
            console.error('Error copying contents to clipboard:', error);
        }
    };

    return (
        <div className="modal modal-open">
            <div className="modal-box flex flex-col items-center gap-4 w-11/12 max-w-5xl">
                <h3 className="font-bold text-xl text-center">Presentation Results</h3>
                <p className="text-md text-center">Slides without text were ignored.</p>
                <div className="w-full h-64 overflow-y-auto bg-gray-100 border p-2 text-sm">
                    <pre>{textPreview || 'Loading...'}</pre>
                </div>
                <button className="btn btn-success" onClick={handleDownload}>
                    Download .txt
                </button>
                <button className="btn btn-primary" onClick={handleCopyToClipboard}>
                    Copy to Clipboard
                </button>
                <div className="modal-action w-full text-right">
                    <button className="btn" onClick={() => setIsOpen(false)}>Done</button>
                </div>
            </div>
        </div>
    );
}

export default PresentationResultsModal;
