import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { StateContext } from '../stateContext';
import apiClient from "../utils/apiClient";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import PresentationResultsModal from '../components/PresentationResultsModal';
import PricingPage from '../components/PricingPage';


function UploadsPage() {
  const [uploads, setUploads] = useState(null);
  const {setToastData} = useContext(StateContext);
  const [isOpen, setIsOpen] = useState(false);
  const [downloadURL, setDownloadURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Deleting Upload")
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [checkedUploads, setCheckedUploads] = useState(new Set());
  const [showPricing, setShowPricing] = useState(false);

  const { user, fetchUserProfile, profile } = useUser();
  const navigate = useNavigate();


 const handleCheckboxChange = (uploadId) => {
    setCheckedUploads((prevCheckedUploads) => {
      const updatedCheckedUploads = new Set(prevCheckedUploads);
      if (updatedCheckedUploads.has(uploadId)) {
        updatedCheckedUploads.delete(uploadId);
      } else {
        updatedCheckedUploads.add(uploadId);
      }
      return updatedCheckedUploads;
    });
  };


  // useEffect(() => {
  //   const fetchTextFile = async () => {
  //     if (isOpen) {
  //       try {
  //         const response = await axios.get(downloadURL);
  //         setTextPreview(response.data);
  //       } catch (error) {
  //         console.error('Error fetching the text file:', error);
  //       }
  //     }
  //   };

  //   fetchTextFile();
  // }, [isOpen, downloadURL]);

const deleteSelectedUploads = async () => {
    setLoadingText("Deleting Upload")
    setIsLoading(true)
    const checkedUploadsArray = Array.from(checkedUploads);
    console.log('Deleting uploads:', checkedUploadsArray);
    try {
        const deleteUploadResponse = await apiClient.post('/deleteUploads', {
            uploads: checkedUploadsArray,
            deleteUploads: true,
        });
        console.log(deleteUploadResponse.data);

        // Assuming the backend sends back the IDs of the successfully deleted uploads
        if (deleteUploadResponse.data.success) {
            // Filter out the deleted uploads from the uploads list
            const remainingUploads = uploads.filter(upload => !checkedUploadsArray.includes(upload.id));
            setUploads(remainingUploads); // Update the uploads state to exclude the deleted ones

            // Clear the selection after deletion
            setCheckedUploads(new Set());
            setIsLoading(false)
        }
    } catch (error) {
        console.error('Error deleting uploads:', error);
        setIsLoading(false)
    }
};

  async function getUploads() {
    try {
      console.log("Fetching upload URLs...");
      fetchUserProfile();
      const uploadsResponse = await apiClient.get('/getUploads');
      console.log(uploadsResponse.data)
      setUploads(uploadsResponse.data.uploads)
      setInitiallyLoaded(true)
    } catch (error){
      console.log(error)
    }
  }

  async function handleUploadOpen(upload){
    if (!upload) navigate('/upload')
    else if (upload?.mediaContent === "presentation"){
      setLoadingText("Loading Presentation Results")
      setIsLoading(true)
      try {

        const response = await apiClient.get(`/getUploadDetails/${upload.id}`);
        console.log({response})
        const resultsURL = response?.data?.results?.resultsUrl
        console.log({resultsURL})
        if(resultsURL) {
          setDownloadURL(resultsURL)
          setIsOpen(true)
        }
      } catch {  
        setToastData({text:"Results not yet available.", show:true})
      } finally {
      setIsLoading(false)
      }

    }
    else navigate(`/view-upload/${upload.id}`)
  }

  useEffect(() => {
    if (!uploads && user) getUploads()
  }, [user]);

  return (
<div className="w-3/4 mx-auto p-4 border rounded-lg shadow-lg">
  {isLoading && 
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex flex-col justify-center items-center">
    <div className="bg-neutral p-4 rounded-lg flex flex-col items-center justify-center space-y-2">
      <span className="loading loading-spinner loading-lg text-white"></span>
      <p className="text-white text-lg">{loadingText}{loadingText === "Deleting Upload" && checkedUploads.size > 1 ? "s" : ""}</p>
    </div>
  </div>}
  {showPricing && (
    <div className="modal modal-open">
      <div className="modal-box flex flex-col items-center gap-2 w-11/12 max-w-5xl">
      <div className="flex flex-col items-center gap-4">
        <p className="text-2xl font-bold">Purchase more credits</p>
        <p className="italic text-sm text-center">1 credit = 1 processed video frame or photo.</p>
        <div className="stats shadow">
          <div className="stat">
            <div className="stat-title">Credit Balance:</div>
            <div className="stat-value">{profile?.credits || 0}</div>
            <div className="stat-desc">credits</div>
          </div>
        </div>
      </div>
        <PricingPage/>
        <div className="modal-action w-full text-right">
          <button className="btn" onClick={() => setShowPricing(false)}>Close</button>
        </div>
      </div>
    </div>)}
  {isOpen && <PresentationResultsModal setIsOpen={setIsOpen} downloadURL={downloadURL} setToastData={setToastData}/>}
  <h2 className="text-2xl font-semibold text-center">Uploads</h2>
  <p className="text-center mb-4">Uploads are deleted after 30 days.</p>
  {profile?.credits >= 0 && 
    <div className="flex flex-col justify-center mx-auto items-center pb-4">
      <div className="stats shadow">
      <div className="stat">
        <div className="stat-title">Credit Balance:</div>
        <div className="stat-value">{profile?.credits}</div>
        <div className="stat-desc">credits</div>
      <button className="btn btn-sm mt-2" onClick={()=>setShowPricing(true)}>Purchase Credits</button>  
      </div>
      </div>
    </div>}
  <div className="flex justify-center w-full pb-4">
    <button className="btn btn-success" onClick={() => handleUploadOpen()}>New Upload</button>
  </div>
  <div className="flex flex-wrap justify-center gap-4">
     {/* {!isLoading && <div
      className="flex justify-center items-center w-18 h-18 p-8 bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300"
      onClick={() => handleUploadOpen()} // Replace navigateToUploadPage with your actual navigation function
      title="Uploads"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
      </svg>
    </div>} */}
    {!initiallyLoaded ? (
      <>
        {Array.from({ length: 4 }, (_, index) => (
          <div key={index} className=" p-2 rounded-lg w-52 h-52">
            <div className="h-32 bg-gray-300 rounded-lg skeleton"></div>
            <div className="mt-2 space-y-2">
              <div className="h-4 bg-gray-300 rounded skeleton"></div>
              <div className="h-4 bg-gray-300 rounded skeleton"></div>
              <div className="h-4 bg-gray-300 rounded skeleton"></div>
            </div>
          </div>
        ))}
      </>
    ) : uploads && uploads.length > 0 ? (
      uploads.map((upload) => (
        <div key={upload.id} className="flex items-center gap-2 border p-2 rounded-lg w-full sm:w-auto sm:max-w-sm">
          <img
            src={upload.imageUrl}
            alt={upload.name}
            title="Click to open upload"
            className="w-32 h-32 object-cover rounded-lg cursor-pointer"
            onClick={() => handleUploadOpen(upload)}
          />
          <div className="flex flex-col">
            <p className="text-lg font-medium">{upload.uploadName}</p>
            <p className="text-sm text-gray-500">{new Date(upload.uploadedAt).toLocaleString().split(", ")[0]}</p>
            <p className="text-sm text-gray-500">{new Date(upload.uploadedAt).toLocaleString().split(", ")[1]}</p>
            {upload?.mediaContent !== "numerical" && <p className="text-xs text-gray-500">{upload.mediaContent}</p>}
            <label className="cursor-pointer label">
              <input
                type="checkbox"
                className="checkbox checkbox-normal"
                checked={checkedUploads.has(upload.id)}
                onChange={() => handleCheckboxChange(upload.id)}
              />
            </label>
          </div>
          
        </div>
      ))
    ) : (
      <div className="text-center text-lg w-full">No uploads yet</div>
    )}
   
  </div>
  {uploads && uploads.length > 0 && <button className={`mt-4 btn w-full sm:w-auto ${Array.from(checkedUploads).length > 0 ? "btn-error" : "btn-disabled"}`} onClick={deleteSelectedUploads}>Delete Selected</button>}
</div>

  );
}

export default UploadsPage;
